import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Box, 
  Paper, 
  CircularProgress, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  TablePagination,
  TableSortLabel,
  TextField
} from '@mui/material';
import api from '../services/api';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const SuperResellers = () => {
  const navigate = useNavigate();
  const [superResellers, setSuperResellers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalClients, setTotalClients] = useState(0);
  const [onDate, setOnDate] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchSuperResellers();
  }, []);

  const fetchSuperResellers = async () => {
    try {
      const response = await api.get('/admin/list');
      setSuperResellers(response.data.data);
      setTotalClients(response.data.totalClients);
      setOnDate(response.data.onDate);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching super resellers:', error);
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const filteredSuperResellers = superResellers.filter(reseller =>
    Object.values(reseller).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const sortedSuperResellers = React.useMemo(() => {
    const comparator = (a, b) => {
      if (b[orderBy] < a[orderBy]) {
        return order === 'asc' ? 1 : -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return order === 'asc' ? -1 : 1;
      }
      return 0;
    };

    return [...filteredSuperResellers].sort(comparator);
  }, [filteredSuperResellers, order, orderBy]);

  const headCells = [
    { id: 'name', label: 'Name' },
    { id: 'credits', label: 'Credits', numeric: true },
    { id: 'description', label: 'Description' },
    { id: 'resellers', label: 'Resellers', numeric: true },
    { id: 'dealers', label: 'Dealers', numeric: true },
    { id: 'clients', label: 'Clients', numeric: true },
  ];

  const handleResellerClick = (superResellerName) => {
    navigate(`/update-super-reseller/${superResellerName}`);
  };

  return (
    <Box sx={{ width: '100%', padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Super Resellers
      </Typography>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="body1">Total Clients: {totalClients}</Typography>
        <Typography variant="body1">Date: {moment(onDate).format('YYYY-MM-DD HH:mm:ss')}</Typography>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ width: 300 }}
        />
      </Box>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 400 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <TableContainer>
              <Table stickyHeader aria-label="super resellers table">
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={() => handleRequestSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedSuperResellers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((superreseller, index) => (
                      <TableRow hover key={superreseller.accountId}>
                        <TableCell 
                          onClick={() => handleResellerClick(superreseller.name)}
                          style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                        >
                          {superreseller.name}
                        </TableCell>
                        <TableCell align="right">{superreseller.credits}</TableCell>
                        <TableCell>{superreseller.description}</TableCell>
                        <TableCell align="right">{superreseller.resellers}</TableCell>
                        <TableCell align="right">{superreseller.dealers}</TableCell>
                        <TableCell align="right">{superreseller.clients}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={filteredSuperResellers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Paper>
    </Box>
  );
};

export default SuperResellers;