import React, { useState } from 'react';
import { 
  Typography, 
  Box, 
  Card, 
  CardContent,
  CardHeader,
  TextField, 
  Button, 
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Snackbar,
  Alert,
  styled,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useTheme,
  alpha
} from '@mui/material';
import MaskedInput from 'react-text-mask';
import api from '../services/api';
import moment from 'moment';

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={['0', '0', ':' , '1', 'A', ':', '7', '9', ':', /[A-Fa-f0-9]/, /[A-Fa-f0-9]/, ':', /[A-Fa-f0-9]/, /[A-Fa-f0-9]/, ':', /[A-Fa-f0-9]/, /[A-Fa-f0-9]/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};

const ExpirySpan = styled('span')(({ theme, expiryStatus }) => ({
  padding: '4px 8px',
  borderRadius: '4px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '0.75rem',
  backgroundColor: 
    expiryStatus === 'expired' ? theme.palette.error.light :
    expiryStatus === 'expiring' ? theme.palette.warning.light :
    theme.palette.success.light,
  color: 
    expiryStatus === 'expired' ? theme.palette.error.contrastText :
    expiryStatus === 'expiring' ? theme.palette.warning.contrastText :
    theme.palette.success.contrastText,
}));

const DeleteMac = () => {
  const [macAddress, setMacAddress] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const theme = useTheme();

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await api.get(`/admin/searchmac/${macAddress}`);
      setSearchResult(response.data);
    } catch (error) {
      console.error('Error searching MAC:', error);
      let errorMessage = 'Error searching MAC. Please try again.';
      
      if (error.response && error.response.status === 400 && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      
      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error'
      });
      setSearchResult(null);
    }
    setLoading(false);
  };

  const handleDeleteConfirmation = () => {
    setConfirmDialogOpen(true);
  };

  const handleDelete = async () => {
    setLoading(true);
    setConfirmDialogOpen(false);
    try {
      const response = await api.post('/admin/remove', { name: searchResult.name });
      if (response.data.status === 'OK') {
        setSnackbar({
          open: true,
          message: 'MAC address deleted successfully!',
          severity: 'success'
        });
        setSearchResult(null);
        setMacAddress('');
      } else {
        throw new Error('Failed to delete MAC address');
      }
    } catch (error) {
      console.error('Error deleting MAC:', error);
      let errorMessage = 'Error deleting MAC. Please try again.';
      
      if (error.response && error.response.status === 400 && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      
      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error'
      });
    }
    setLoading(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const getExpiryStatus = (expiryDate) => {
    const now = moment();
    const expiry = moment(expiryDate);
    const daysUntilExpiry = expiry.diff(now, 'days');

    if (daysUntilExpiry < 0) {
      return 'expired';
    } else if (daysUntilExpiry <= 10) {
      return 'expiring';
    } else {
      return 'active';
    }
  };

  const getConfirmationMessage = () => {
    if (!searchResult) return '';

    const expiryStatus = getExpiryStatus(searchResult.expiry);
    const formattedExpiry = moment(searchResult.expiry).format('YYYY-MM-DD');

    if (expiryStatus === 'expired') {
      return (
        <span>
          Are you sure you want to delete this MAC address <strong>{searchResult.mac}</strong>?
        </span>
      );
    } else {
      return (
        <span>
          This account <strong>{searchResult.mac}</strong> has not expired. 
          It will be expiring on <strong>{formattedExpiry}</strong>. 
          Are you sure you want to delete this MAC address?
        </span>
      );
    }
  };

  const cardStyle = {
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    '&:hover': {
      boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
    },
  };

  const cardHeaderStyle = {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    color: theme.palette.common.black,
    padding: '16px',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  };

  const cardTitleStyle = {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: theme.palette.common.black,
  };

  return (
    <Box sx={{ width: '100%', padding: 3 }}>
      <Card sx={cardStyle}>
        <CardHeader 
          title="Delete MAC"
          titleTypographyProps={{ variant: 'h6', ...cardTitleStyle }}
          sx={cardHeaderStyle}
        />
        <Divider />
        <CardContent>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <TextField
              label="MAC Address"
              value={macAddress}
              onChange={(e) => setMacAddress(e.target.value)}
              InputProps={{
                inputComponent: TextMaskCustom,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{ mr: 2, flexGrow: 1 }}
            />
            <Button 
              variant="contained" 
              onClick={handleSearch}
              disabled={loading || macAddress.length !== 17}
            >
              {loading ? <CircularProgress size={24} /> : 'Search'}
            </Button>
          </Box>
          {searchResult && (
            <Box sx={{ mt: 3 }}>
              <TableContainer component={Card}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>MAC</TableCell>
                      <TableCell>Expiry</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{searchResult.name}</TableCell>
                      <TableCell>{searchResult.mac}</TableCell>
                      <TableCell>
                        <ExpirySpan expiryStatus={getExpiryStatus(searchResult.expiry)}>
                          {moment(searchResult.expiry).format('YYYY-MM-DD')}
                        </ExpirySpan>
                      </TableCell>
                      <TableCell>
                        <Button 
                          variant="contained" 
                          color="error" 
                          onClick={handleDeleteConfirmation}
                          disabled={loading}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </CardContent>
      </Card>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {getConfirmationMessage()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>No</Button>
          <Button onClick={handleDelete} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeleteMac;