import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchClients } from '../features/clients/clientsSlice';
import api from '../services/api';
import { 
  Box, 
  Grid, 
  Paper, 
  Typography, 
  Card, 
  CardContent, 
  CardActionArea,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  TableSortLabel,
  styled,
} from '@mui/material';
import moment from 'moment';
import PeopleIcon from '@mui/icons-material/People';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const DashboardCard = ({ title, count, onClick, backgroundColor, percentage, icon: Icon }) => (
  <Grid item xs={12} sm={6} md={3}>
    <Card 
      sx={{ 
        backgroundColor, 
        boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
        '&:hover': {
          boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        },
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <CardActionArea onClick={onClick}>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative', zIndex: 1 }}>
          <Box>
            <Typography variant="subtitle1" component="div" sx={{ color: 'rgba(255, 255, 255, 0.85)', fontWeight: 'medium' }}>
              {title}
            </Typography>
            <Typography variant="h3" component="div" sx={{ color: 'white', mt: 1 }}>
              {count}
            </Typography>
          </Box>
          {percentage !== undefined && (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
              <CircularProgress 
                variant="determinate" 
                value={percentage} 
                size={60} 
                thickness={5} 
                sx={{ color: 'white' }}
              />
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="body2" component="div" sx={{ color: 'white', fontWeight: 'bold' }}>
                  {`${Math.round(percentage)}%`}
                </Typography>
              </Box>
            </Box>
          )}
        </CardContent>
        <Box
          sx={{
            position: 'absolute',
            top: '-20%',
            left: '-10%',
            opacity: 0.2,
            transform: 'rotate(-10deg)',
            zIndex: 0,
          }}
        >
          <Icon sx={{ fontSize: 180, color: 'white' }} />
        </Box>
      </CardActionArea>
    </Card>
  </Grid>
);

const TypeSpan = styled('span')(({ theme, type }) => ({
  padding: '4px 8px',
  borderRadius: '4px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '0.75rem',
  backgroundColor: 
    type === 'USED' ? theme.palette.error.light :
    type === 'RETRIEVED' ? theme.palette.success.light :
    type === 'DELETE' ? theme.palette.error.light :
    'transparent',
  color: 
    type === 'USED' || type === 'RETRIEVED' || type === 'DELETE' ? theme.palette.common.white :
    theme.palette.text.primary,
}));

const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clients, status } = useSelector((state) => state.clients);
  const [credits, setCredits] = useState(null);
  const [creditsLoading, setCreditsLoading] = useState(true);
  const [dealerTransactions, setDealerTransactions] = useState([]);
  const [dealerTransactionsLoading, setDealerTransactionsLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [orderBy, setOrderBy] = useState('created');
  const [order, setOrder] = useState('desc');

  useEffect(() => {
    dispatch(fetchClients());
    fetchCredits();
    fetchDealerTransactions();
  }, [dispatch]);

  const fetchCredits = async () => {
    try {
      const response = await api.get('/api/credits');
      setCredits(response.data.credits);
      setCreditsLoading(false);
    } catch (error) {
      console.error('Error fetching credits:', error);
      setCreditsLoading(false);
    }
  };

  const fetchDealerTransactions = async () => {
    try {
      const response = await api.get('/api/transactions');
      setDealerTransactions(response.data);
      setDealerTransactionsLoading(false);
    } catch (error) {
      console.error('Error fetching dealer transactions:', error);
      setDealerTransactionsLoading(false);
    }
  };

  const getExpiryStatus = (expiryDate) => {
    const now = moment();
    const expiry = moment(expiryDate);
    const daysUntilExpiry = expiry.diff(now, 'days');

    if (daysUntilExpiry < 0) {
      return 'expired';
    } else if (daysUntilExpiry <= 10) {
      return 'expiring';
    } else {
      return 'active';
    }
  };

  const counts = {
    all: clients.length,
    active: clients.filter(client => {
      const status = getExpiryStatus(client.expiry);
      return status === 'active' || status === 'expiring';
    }).length,
    expired: clients.filter(client => getExpiryStatus(client.expiry) === 'expired').length,
    expiring: clients.filter(client => getExpiryStatus(client.expiry) === 'expiring').length,
  };

  const percentages = {
    active: (counts.active / counts.all) * 100,
    expired: (counts.expired / counts.all) * 100,
    expiring: (counts.expiring / counts.all) * 100,
  };

  const handleCardClick = (filter) => {
    navigate(`/clients?filter=${filter}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filteredTransactions = dealerTransactions.filter(transaction =>
    Object.values(transaction).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const sortedTransactions = filteredTransactions.sort((a, b) => {
    if (b[orderBy] < a[orderBy]) {
      return order === 'asc' ? 1 : -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return order === 'asc' ? -1 : 1;
    }
    return 0;
  });

  const headCells = [
    { id: 'created', label: 'Date' },
    { id: 'type', label: 'Type' },
    { id: 'credits', label: 'Credits', numeric: true },
    { id: 'login', label: 'Login' },
    { id: 'mac', label: 'MAC' },
    { id: 'description', label: 'Description' },
    { id: 'expiry', label: 'Expiry' },
  ];

  return (
    <Box sx={{ flexGrow: 1, p: 0 }}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <Card 
            sx={{ 
              backgroundColor: 'rgb(44,62,80)', 
              boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
              '&:hover': {
                boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
              },
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <CardContent>
              <Typography variant="subtitle1" component="div" sx={{ color: 'rgba(255, 255, 255, 0.85)', fontWeight: 'medium', position: 'relative', zIndex: 1 }}>
                Credits Remaining
              </Typography>
              <Typography variant="h3" component="div" sx={{ color: 'white', mt: 1, position: 'relative', zIndex: 1 }}>
                {creditsLoading ? <CircularProgress size={24} /> : credits}
              </Typography>
            </CardContent>
            <Box
              sx={{
                position: 'absolute',
                top: '-20%',
                left: '-10%',
                opacity: 0.2,
                transform: 'rotate(-10deg)',
                zIndex: 0,
              }}
            >
              <AttachMoneyIcon sx={{ fontSize: 180, color: 'white' }} />
            </Box>
          </Card>
        </Grid>
        <DashboardCard 
          title="All Clients" 
          count={counts.all} 
          onClick={() => handleCardClick('all')} 
          backgroundColor="rgb(75,119,190)"
          icon={PeopleIcon}
        />
        <DashboardCard 
          title="Active Clients" 
          count={counts.active} 
          onClick={() => handleCardClick('active')} 
          backgroundColor="rgb(38,194,129)"
          percentage={percentages.active}
          icon={CheckCircleIcon}
        />
        <DashboardCard 
          title="Expired Clients" 
          count={counts.expired} 
          onClick={() => handleCardClick('expired')} 
          backgroundColor="rgb(217,30,24)"
          percentage={percentages.expired}
          icon={ErrorIcon}
        />
        <DashboardCard 
          title="Expiring Soon" 
          count={counts.expiring} 
          onClick={() => handleCardClick('expiring')} 
          backgroundColor="orange"
          percentage={percentages.expiring}
          icon={WarningIcon}
        />
      </Grid>
      
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
          <Typography variant="h5">
            Latest Dealer Transactions
          </Typography>
          <TextField
            label="Search Transactions"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ width: 300 }}
          />
        </Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="dealer transactions table">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={() => handleRequestSort(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dealerTransactionsLoading ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : sortedTransactions.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No dealer transactions found
                  </TableCell>
                </TableRow>
              ) : (
                sortedTransactions
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((transaction, index) => (
                    <TableRow key={index}>
                      <TableCell>{moment(transaction.created).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                      <TableCell>
                        <TypeSpan type={transaction.type}>
                          {transaction.type}
                        </TypeSpan>
                      </TableCell>
                      <TableCell align="right">{transaction.credits}</TableCell>
                      <TableCell>{transaction.login}</TableCell>
                      <TableCell>{transaction.mac}</TableCell>
                      <TableCell>{transaction.description}</TableCell>
                      <TableCell>{moment(transaction.expiry).format('YYYY-MM-DD')}</TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredTransactions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default Dashboard;