import axios from 'axios';

//const API_BASE_URL = 'http://localhost:3700';
const API_BASE_URL = 'https://huba1.net';
//const API_BASE_URL = 'https://sbug.mine.nu:3701';

const api = axios.create({
  baseURL: API_BASE_URL,
});

// Add a request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Assuming you store the token in localStorage after login
    if (token) {
      config.headers['x-access-token'] = token; // Changed from 'Authorization' to 'x-access-token'
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const authenticate = (login_username, login_password, g_recaptcha_response) => {
  return api.post('/authenticate', { login_username, login_password, g_recaptcha_response });
};

// Updated fetchClients function without search and page parameters
export const fetchClients = () => {
  return api.get('/api/clients');
};

// Add other API calls here
export const fetchUsers = () => {
  return api.get('/users');
};

// ... other API calls

export const fetchMessage = () => {
  return api.get('/api/message');
};

export const updateSettings = (settings) => {
  return api.post('/updatesettings', settings);
};

export default api;
