import React from 'react';
import { Fab } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { useSelector } from 'react-redux';

const SettingsIconButton = ({ onClick }) => {
  const { layout } = useSelector((state) => state.auth.settings);

  return (
    <Fab
      color="primary"
      aria-label="settings"
      onClick={onClick}
      sx={{
        position: 'fixed',
        top: '50%',
        transform: 'translateY(-50%)',
        left: layout === 'right' ? 16 : 'auto',
        right: layout === 'left' ? 16 : 'auto',
        zIndex: 1000
      }}
    >
      <SettingsIcon />
    </Fab>
  );
};

export default SettingsIconButton;
