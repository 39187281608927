import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchCredits } from '../features/auth/authSlice';
import { 
  Box, 
  Grid, 
  Card, 
  CardContent, 
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  styled,
  useTheme,
  alpha,
} from '@mui/material';
import moment from 'moment';
import api from '../services/api';

const TypeSpan = styled('span')(({ theme, type }) => ({
  padding: '4px 8px',
  borderRadius: '4px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '0.75rem',
  backgroundColor: 
    type === 'USED' ? theme.palette.error.light :
    type === 'RETRIEVED' ? theme.palette.success.light :
    'transparent',
  color: 
    type === 'USED' || type === 'RETRIEVED' ? theme.palette.common.white :
    theme.palette.text.primary,
}));

const UpdateReseller = () => {
  const { resellerName } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [resellerData, setResellerData] = useState({
    name: '',
    credits: 0,
    description: '',
  });
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('created');
  const [order, setOrder] = useState('desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [creditsAction, setCreditsAction] = useState(0);
  const [creditsAmount, setCreditsAmount] = useState(1);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    if (resellerName) {
      fetchResellerData();
      fetchTransactions();
    }
  }, [resellerName]);

  const fetchResellerData = async () => {
    try {
      const response = await api.get(`/superreseller/reseller/${resellerName}`);
      setResellerData(prevData => ({
        ...prevData,
        ...response.data,
        name: resellerName
      }));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching reseller data:', error);
      setLoading(false);
    }
  };

  const fetchTransactions = async () => {
    try {
      const response = await api.get(`/superreseller/history/${resellerName}`);
      setTransactions(response.data);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setResellerData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async () => {
    try {
      const response = await api.post('/superreseller/save', {
        name: resellerName,
        credits: creditsAmount,
        desc: resellerData.description,
        transaction: creditsAction
      });
      if (response.data.status === 'OK') {
        setSnackbar({
          open: true,
          message: 'Reseller updated successfully!',
          severity: 'success'
        });
        
        // Fetch updated credits
        dispatch(fetchCredits());
        
        // Redirect after a short delay
        setTimeout(() => {
          navigate('/resellers');
        }, 2000);
      } else {
        throw new Error('Update failed');
      }
    } catch (error) {
      console.error('Error updating reseller:', error);
      setSnackbar({
        open: true,
        message: 'Error updating reseller. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const filteredTransactions = transactions.filter(transaction =>
    Object.values(transaction).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const sortedTransactions = filteredTransactions.sort((a, b) => {
    if (b[orderBy] < a[orderBy]) {
      return order === 'asc' ? 1 : -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return order === 'asc' ? -1 : 1;
    }
    return 0;
  });

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setSnackbar({
        open: true,
        message: 'Passwords do not match',
        severity: 'error'
      });
      return;
    }

    try {
      const response = await api.post('/superreseller/changepassword', {
        name: resellerName,
        password: newPassword
      });

      if (response.data.status === 'OK') {
        setSnackbar({
          open: true,
          message: 'Password changed successfully!',
          severity: 'success'
        });
        setNewPassword('');
        setConfirmPassword('');
      } else {
        throw new Error('Password change failed');
      }
    } catch (error) {
      console.error('Error changing password:', error);
      setSnackbar({
        open: true,
        message: 'Error changing password. Please try again.',
        severity: 'error'
      });
    }
  };

  const headCells = [
    { id: 'created', label: 'Date' },
    { id: 'type', label: 'Type' },
    { id: 'credits', label: 'Credits', numeric: true },
    { id: 'description', label: 'Description' },
    { id: 'expiry', label: 'Expiry' },
  ];

  const cardStyle = {
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    '&:hover': {
      boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
    },
  };

  const cardHeaderStyle = {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    color: theme.palette.common.black,
    padding: '16px',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  };

  const cardTitleStyle = {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: theme.palette.common.black,
  };

  const creditOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1500, 2000, 2500, 5000, 10000];

  return (
    <Box sx={{ width: '100%', padding: 3 }}>
      <Grid container spacing={3}>
        {/* Update Reseller Card */}
        <Grid item xs={12} md={6}>
          <Card sx={cardStyle}>
            <CardHeader 
              title={`Update ${resellerName}`}
              subheader={`Credits: ${resellerData.credits}`}
              titleTypographyProps={{ variant: 'h6', ...cardTitleStyle }}
              sx={{
                ...cardHeaderStyle,
                '& .MuiCardHeader-action': {
                  alignSelf: 'center',
                },
              }}
            />
            <Divider />
            <CardContent>
              <FormControl fullWidth margin="normal">
                <InputLabel>Credits Action</InputLabel>
                <Select
                  value={creditsAction}
                  onChange={(e) => setCreditsAction(e.target.value)}
                >
                  <MenuItem value={0}>Add</MenuItem>
                  <MenuItem value={1}>Remove</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth margin="normal">
                <InputLabel>Credits Amount</InputLabel>
                <Select
                  value={creditsAmount}
                  onChange={(e) => setCreditsAmount(e.target.value)}
                >
                  {creditOptions.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                margin="normal"
                label="Description"
                name="description"
                multiline
                rows={4}
                value={resellerData.description}
                onChange={handleInputChange}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{ mt: 2 }}
              >
                Update Reseller
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Change Password Card */}
        <Grid item xs={12} md={6}>
          <Card sx={cardStyle}>
            <CardHeader 
              title="Change Password"
              titleTypographyProps={{ variant: 'h6', ...cardTitleStyle }}
              sx={cardHeaderStyle}
            />
            <Divider />
            <CardContent>
              <TextField
                fullWidth
                margin="normal"
                label="New Password"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Confirm New Password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handlePasswordChange}
                disabled={!newPassword || !confirmPassword || newPassword !== confirmPassword}
                sx={{ mt: 2 }}
              >
                Change Password
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Transactions Card */}
        <Grid item xs={12}>
          <Card sx={cardStyle}>
            <CardHeader 
              title="Transactions"
              titleTypographyProps={{ variant: 'h6', ...cardTitleStyle }}
              action={
                <TextField
                  label="Search Transactions"
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  sx={{ width: 200 }}
                />
              }
              sx={{
                ...cardHeaderStyle,
                display: 'flex',
                alignItems: 'center',
                '& .MuiCardHeader-action': {
                  margin: 0,
                  alignSelf: 'center',
                },
              }}
            />
            <Divider />
            <CardContent>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} size="small">
                  <TableHead>
                    <TableRow>
                      {headCells.map((headCell) => (
                        <TableCell
                          key={headCell.id}
                          align={headCell.numeric ? 'right' : 'left'}
                          sortDirection={orderBy === headCell.id ? order : false}
                        >
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={() => handleRequestSort(headCell.id)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : sortedTransactions.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No transactions found
                        </TableCell>
                      </TableRow>
                    ) : (
                      sortedTransactions
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((transaction, index) => (
                          <TableRow key={index}>
                            <TableCell>{moment(transaction.created).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                            <TableCell>
                              <TypeSpan type={transaction.type}>
                                {transaction.type}
                              </TypeSpan>
                            </TableCell>
                            <TableCell align="right">{transaction.credits}</TableCell>
                            <TableCell>{transaction.description}</TableCell>
                            <TableCell>{moment(transaction.expiry).format('YYYY-MM-DD')}</TableCell>
                          </TableRow>
                        ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredTransactions.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UpdateReseller;