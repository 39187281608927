import React, { useState, useMemo, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Modal, Box, Typography, Button, Backdrop, CircularProgress } from '@mui/material';
import { store } from './app/store';
import { restoreAuth, logout, updateSettings } from './features/auth/authSlice';
import api from './services/api';
import Dashboard from './components/Dashboard';
import Users from './components/Users';
import Layout from './components/Layout';
import Login from './components/Login';
import Clients from './components/Clients';
import NewClient from './components/NewClient';
import UpdateClient from './components/UpdateClient';
import AdminPanel from './components/AdminPanel';
import SuperResellers from './components/SuperResellers';
import AddSuperReseller from './components/AddSuperReseller';
import UpdateSuperReseller from './components/UpdateSuperReseller';
import SuperResellerDashboard from './components/SuperResellerDashboard';
import AddReseller from './components/AddReseller';
import Resellers from './components/Resellers';
import UpdateReseller from './components/UpdateReseller';
import ResellerDashboard from './components/ResellerDashboard';
import AddDealer from './components/AddDealer';
import Dealers from './components/Dealers';
import UpdateDealer from './components/UpdateDealer';
import Message from './components/Message';
import DeleteMac from './components/DeleteMac';
import SettingsIcon from './components/SettingsIcon';
import SettingsDrawer from './components/SettingsDrawer';
import SettingsIconButton from './components/SettingsIcon';

export const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

const LoadingScreen = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      bgcolor: 'background.default',
    }}
  >
    <CircularProgress size={60} thickness={4} />
    <Typography variant="h6" sx={{ mt: 2 }}>
      Loading...
    </Typography>
  </Box>
);

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useSelector((state) => state.auth);
  
  if (isLoading) {
    return <LoadingScreen />;
  }
  
  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

function AppContent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, isLoading, role, settings } = useSelector((state) => state.auth);
  const [message, setMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        dispatch(updateSettings({ mode: settings.mode === 'light' ? 'dark' : 'light' }));
      },
    }),
    [dispatch, settings.mode]
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: settings.mode,
          primary: {
            main: getColorFromPreset(settings.preset),
          },
          ...(settings.mode === 'light'
            ? {
                background: { default: '#f5f5f5', paper: '#ffffff' },
              }
            : {
                background: { default: '#161c24', paper: '#202a35' },
              }),
        },
      }),
    [settings.mode, settings.preset]
  );

  useEffect(() => {
    dispatch(restoreAuth());
  }, [dispatch]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchMessage();
    }
  }, [isAuthenticated, role]);

  const fetchMessage = async () => {
    try {
      let response;
      if (role === 'superreseller') {
        response = await api.get('/superreseller/message');
      } else if (role === 'reseller') {
        response = await api.get('/reseller/message');
      } else {
        response = await api.get('/api/message');
      }
      setMessage(response.data.message);
      setOpenModal(true);
    } catch (error) {
      console.error('Error fetching message:', error);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const toggleSettings = () => {
    setSettingsOpen(prev => !prev);
  };

  const handleUpdateSettings = (newSettings) => {
    dispatch(updateSettings(newSettings));
    // You might want to add logic here to update localStorage if needed
  };

  useEffect(() => {
    const checkTokenExpiration = () => {
      const token = localStorage.getItem('token');
      const expirationDate = localStorage.getItem('expirationDate');
      
      if (token && expirationDate) {
        const now = new Date();
        if (now >= new Date(expirationDate)) {
          console.log('Token has expired');
          dispatch(logout());
          navigate('/login');
        }
      }
    };

    // Check immediately
    checkTokenExpiration();

    // Then check every 30 seconds
    const intervalId = setInterval(checkTokenExpiration, 30000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [dispatch, navigate]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/login" element={!isAuthenticated ? <Login /> : <Navigate to="/" />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <Layout>
                  {role === 'admin' ? <Navigate to="/admin" /> : 
                   role === 'superreseller' ? <Navigate to="/superreseller" /> : 
                   role === 'reseller' ? <Navigate to="/reseller" /> : 
                   <Dashboard />}
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route path="/admin" element={<ProtectedRoute><Layout><AdminPanel /></Layout></ProtectedRoute>} />
          <Route path="/superreseller" element={<ProtectedRoute><Layout><SuperResellerDashboard /></Layout></ProtectedRoute>} />
          <Route path="/reseller" element={<ProtectedRoute><Layout><ResellerDashboard /></Layout></ProtectedRoute>} />
          <Route path="/clients" element={<ProtectedRoute><Layout><Clients /></Layout></ProtectedRoute>} />
          <Route path="/new-client" element={<ProtectedRoute><Layout><NewClient /></Layout></ProtectedRoute>} />
          <Route path="/update-client/:clientName" element={<ProtectedRoute><Layout><UpdateClient /></Layout></ProtectedRoute>} />
          <Route path="/super-resellers" element={<ProtectedRoute><Layout><SuperResellers /></Layout></ProtectedRoute>} />
          <Route path="/add-super-reseller" element={<ProtectedRoute><Layout><AddSuperReseller /></Layout></ProtectedRoute>} />
          <Route path="/update-super-reseller/:resellerName" element={<ProtectedRoute><Layout><UpdateSuperReseller /></Layout></ProtectedRoute>} />
          <Route path="/add-reseller" element={<ProtectedRoute><Layout><AddReseller /></Layout></ProtectedRoute>} />
          <Route path="/resellers" element={<ProtectedRoute><Layout><Resellers /></Layout></ProtectedRoute>} />
          <Route path="/update-reseller/:resellerName" element={<ProtectedRoute><Layout><UpdateReseller /></Layout></ProtectedRoute>} />
          <Route path="/add-dealer" element={<ProtectedRoute><Layout><AddDealer /></Layout></ProtectedRoute>} />
          <Route path="/dealers" element={<ProtectedRoute><Layout><Dealers /></Layout></ProtectedRoute>} />
          <Route path="/update-dealer/:dealerName" element={<ProtectedRoute><Layout><UpdateDealer /></Layout></ProtectedRoute>} />
          <Route path="/admin/message" element={<ProtectedRoute><Layout><Message /></Layout></ProtectedRoute>} />
          <Route path="/admin/delete-mac" element={<ProtectedRoute><Layout><DeleteMac /></Layout></ProtectedRoute>} />
        </Routes>
        {isAuthenticated && (
          <>
            <SettingsIconButton onClick={() => setSettingsOpen(true)} />
            <SettingsDrawer 
              open={settingsOpen} 
              onClose={() => setSettingsOpen(false)}
              settings={settings}
              onUpdateSettings={(newSettings) => {
                dispatch(updateSettings(newSettings));
                // You might want to add logic here to update localStorage if needed
              }}
            />
          </>
        )}
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="message-modal-title"
          aria-describedby="message-modal-description"
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              backdropFilter: 'blur(5px)',
            },
          }}
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}>
            <Typography id="message-modal-title" variant="h6" component="h2">
              Message
            </Typography>
            <Typography id="message-modal-description" sx={{ mt: 2 }}>
              {message}
            </Typography>
            <Button onClick={handleCloseModal} sx={{ mt: 2 }}>Close</Button>
          </Box>
        </Modal>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AppContent />
      </Router>
    </Provider>
  );
}

export default App;

const getColorFromPreset = (preset) => {
  switch (preset) {
    case 'green':
      return '#00ab55';
    case 'purple':
      return '#7635dc';
    case 'aqua':
      return '#1ccaff';
    case 'blue':
      return '#2065d1';
    case 'orange':
      return '#fda92d';
    case 'red':
      return '#ff3030';
    default:
      return '#1976d2'; // Default blue
  }
};