import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { authenticate } from '../../services/api';
import api from '../../services/api';

export const loginUser = createAsyncThunk(
  'auth/login',
  async ({ login_username, login_password, g_recaptcha_response }, { rejectWithValue }) => {
    try {
      const response = await authenticate(login_username, login_password, g_recaptcha_response);
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('role', response.data.role);
      
      // Set expiration date (e.g., 1 hour from now)
      const expirationDate = new Date(new Date().getTime() + 60 * 60 * 1000);
      localStorage.setItem('expirationDate', expirationDate.toISOString());
      
      api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchCredits = createAsyncThunk(
  'auth/fetchCredits',
  async (_, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const role = state.auth.role;
      
      let response;
      if (role === 'superreseller') {
        response = await api.get('/superreseller/credits');
      } else if (role === 'reseller') {
        response = await api.get('/reseller/credits');
      } else if (role === 'dealer') {
        response = await api.get('/api/credits');
      } else {
        // For admin or other roles, we might not need to fetch credits
        return null;
      }
      
      return response.data.credits;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const restoreAuth = createAsyncThunk('auth/restoreAuth', async (_, { dispatch }) => {
  const token = localStorage.getItem('token');
  const role = localStorage.getItem('role');
  const expirationDate = localStorage.getItem('expirationDate');
  const mode = localStorage.getItem('settings_mode') || 'light';
  const layout = localStorage.getItem('settings_layout') || 'left';
  const preset = localStorage.getItem('settings_preset') || 'blue';
  
  if (token && role && expirationDate) {
    const now = new Date();
    if (now < new Date(expirationDate)) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      try {
        const response = await api.get('/api/user');
        return { 
          ...response.data, 
          token, 
          role,
          settings: { mode, layout, preset }
        };
      } catch (error) {
        console.error('Error restoring auth:', error);
        // Don't dispatch logout here, just return null
        return null;
      }
    } else {
      console.log('Token has expired');
      // Don't dispatch logout here, just return null
      return null;
    }
  }
  return null;
});

const initialState = {
  user: null,
  isAuthenticated: false,
  role: null,
  credits: 0,
  token: null,
  name: null,
  status: 'idle',
  error: null,
  isLoading: true,
  settings: {
    mode: localStorage.getItem('settings_mode') || 'light',
    layout: localStorage.getItem('settings_layout') || 'left',
    preset: localStorage.getItem('settings_preset') || 'blue'
  }
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.role = null;
      state.credits = 0;
      state.token = null;
      state.name = null;
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      localStorage.removeItem('expirationDate');
      localStorage.removeItem('name');
      delete api.defaults.headers.common['Authorization'];
    },
    updateSettings: (state, action) => {
      state.settings = { ...state.settings, ...action.payload };
      Object.keys(action.payload).forEach(key => {
        localStorage.setItem(`settings_${key}`, action.payload[key]);
      });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.isAuthenticated = true;
        state.user = action.payload.login_username;
        state.role = action.payload.role;
        state.credits = action.payload.credits;
        state.token = action.payload.token;
        state.name = action.payload.name;
        // Update settings from the response, keeping defaults if not provided
        if (action.payload.settings) {
          state.settings = {
            ...state.settings,
            ...action.payload.settings
          };
          // Store settings in localStorage
          Object.keys(action.payload.settings).forEach(key => {
            localStorage.setItem(`settings_${key}`, action.payload.settings[key]);
          });
        }
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchCredits.fulfilled, (state, action) => {
        state.credits = action.payload;
      })
      .addCase(restoreAuth.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(restoreAuth.fulfilled, (state, action) => {
        state.isLoading = false;
        if (action.payload) {
          state.isAuthenticated = true;
          state.user = action.payload.user;
          state.role = action.payload.role;
          state.credits = action.payload.credits;
          state.token = action.payload.token;
          state.name = action.payload.name;
          state.settings = action.payload.settings;
        } else {
          // If payload is null, don't reset the state
          // Instead, we'll rely on the existing localStorage values
          state.isAuthenticated = !!localStorage.getItem('token');
          state.role = localStorage.getItem('role');
          state.token = localStorage.getItem('token');
        }
      })
      .addCase(restoreAuth.rejected, (state) => {
        state.isLoading = false;
        // Don't reset authentication state here
      });
  },
});

export const { logout, updateSettings } = authSlice.actions;

export default authSlice.reducer;
