import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchClients } from '../features/clients/clientsSlice';
import { fetchCredits } from '../features/auth/authSlice';
import api from '../services/api';
import moment from 'moment';
import {
  Typography,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  CircularProgress,
  FormControlLabel,
  Switch,
  TableSortLabel,
  IconButton,
  LinearProgress,
  styled,
  Checkbox,
  Toolbar,
  alpha,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  DialogContentText,
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import FilterListIcon from '@mui/icons-material/FilterList';
import ExportIcon from '@mui/icons-material/GetApp';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const StatusSpan = styled('span')(({ theme, status }) => ({
  padding: '4px 8px',
  borderRadius: '4px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '0.75rem',
  backgroundColor: status ? theme.palette.success.light : theme.palette.error.light,
  color: status ? theme.palette.success.contrastText : theme.palette.error.contrastText,
}));

const ExpirySpan = styled('span')(({ theme, expiryStatus }) => ({
  padding: '4px 8px',
  borderRadius: '4px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '0.75rem',
  backgroundColor: 
    expiryStatus === 'expired' ? theme.palette.error.light :
    expiryStatus === 'expiring' ? theme.palette.warning.light :
    theme.palette.success.light,
  color: 
    expiryStatus === 'expired' ? theme.palette.error.contrastText :
    expiryStatus === 'expiring' ? theme.palette.warning.contrastText :
    theme.palette.success.contrastText,
}));

const EnhancedTableToolbar = (props) => {
  const { numSelected, onRenewSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Clients
        </Typography>
      )}
      {numSelected > 0 && (
        <Tooltip title="Renew selected">
          <IconButton onClick={onRenewSelected}>
            <AutorenewIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

const StatusIcon = styled(FiberManualRecordIcon)(({ theme, status }) => ({
  color: status ? theme.palette.success.light : theme.palette.grey[400], // Changed to light gray for inactive
}));

const StyledTableCell = styled(TableCell)(({ theme, isMobile }) => ({
  ...(isMobile && {
    '&.MuiTableCell-paddingCheckbox': {
      padding: theme.spacing(0, 1, 0, 1),
    },
    '&.MuiTableCell-paddingNone': {
      padding: 0,
    },
    '&.MuiTableCell-root': {
      padding: theme.spacing(0.2),
    },
    '&.name-cell': {
      padding: theme.spacing(0.5, 1),
    },
    // Remove the specific styling for mac-cell
  }),
}));

const Clients = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clients, status, error, count, currentPage, perPage, totalPages } = useSelector((state) => state.clients);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [dense, setDense] = useState(true);  // Changed to true by default
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [filter, setFilter] = useState('all');
  const [exportAnchorEl, setExportAnchorEl] = useState(null);
  const location = useLocation();
  const [renewModalOpen, setRenewModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [renewCredits, setRenewCredits] = useState(0);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [clientToDelete, setClientToDelete] = useState(null);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [expandedRows, setExpandedRows] = useState([]);

  const [multiRenewModalOpen, setMultiRenewModalOpen] = useState(false);
  const [selectedClientsForRenew, setSelectedClientsForRenew] = useState([]);
  const [multiRenewCredits, setMultiRenewCredits] = useState(1);

  useEffect(() => {
    dispatch(fetchClients());
  }, [dispatch]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filterParam = params.get('filter');
    if (filterParam) {
      setFilter(filterParam);
    }
  }, [location]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleNewUser = () => {
    console.log('New Client button clicked');
    // Implement new user functionality here
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleRenew = (client) => {
    setSelectedClient(client);
    setRenewModalOpen(true);
  };

  const handleRenewConfirm = async () => {
    try {
      const response = await api.post('/api/renew', {
        name: selectedClient.name,
        months: renewCredits
      });

      if (response.data.status === 'OK') {
        setSnackbar({
          open: true,
          message: `Successfully renewed ${renewCredits} month(s) for ${selectedClient.name}`,
          severity: 'success'
        });
        
        // Refresh clients data
        dispatch(fetchClients());
        
        // Update credits in the sidebar
        dispatch(fetchCredits());
        
        // Close the modal
        setRenewModalOpen(false);
        setSelectedClient(null);
        setRenewCredits(0);
      } else {
        throw new Error('Renew failed');
      }
    } catch (error) {
      console.error('Error renewing credits:', error);
      setSnackbar({
        open: true,
        message: 'Error renewing credits. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleDelete = (client) => {
    setClientToDelete(client);
    setDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await api.post('/api/remove', {
        name: clientToDelete.name
      });

      if (response.data.status === 'OK') {
        setSnackbar({
          open: true,
          message: `Successfully deleted client ${clientToDelete.name}`,
          severity: 'success'
        });
        
        // Refresh clients data
        dispatch(fetchClients());
        
        // Close the modal
        setDeleteModalOpen(false);
        setClientToDelete(null);
      } else {
        throw new Error('Delete failed');
      }
    } catch (error) {
      console.error('Error deleting client:', error);
      setSnackbar({
        open: true,
        message: 'Error deleting client. Please try again.',
        severity: 'error'
      });
    }
  };

  const sortedClients = React.useMemo(() => {
    const comparator = (a, b) => {
      if (b[orderBy] < a[orderBy]) {
        return order === 'asc' ? 1 : -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return order === 'asc' ? -1 : 1;
      }
      return 0;
    };

    return [...clients].sort(comparator);
  }, [clients, order, orderBy]);

  const headCells = [
    { id: 'name', label: 'Name' },
    { id: 'mac', label: 'MAC' },
    { id: 'ministra', label: 'Ministra' },
    { id: 'expiry', label: 'Expiry' },
    { id: 'description', label: 'Description' },
    { id: 'status', label: 'Status' },
    { id: 'actions', label: 'Actions', sortable: false },
  ];

  const getErrorMessage = (error) => {
    if (typeof error === 'string') return error;
    if (error && error.message) return error.message;
    return 'An unknown error occurred';
  };

  const formatExpiryDate = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  const getExpiryStatus = (expiryDate) => {
    const now = moment();
    const expiry = moment(expiryDate);
    const daysUntilExpiry = expiry.diff(now, 'days');

    if (daysUntilExpiry < 0) {
      return 'expired';
    } else if (daysUntilExpiry <= 10) {
      return 'expiring';
    } else {
      return 'active';
    }
  };

  const handleSelectAllClick = (event) => {
    let newSelected = [];
    if (event.target.checked) {
      newSelected = sortedClients.map((client) => client.name);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
    console.log("Select all clicked. New selected:", newSelected);
  };

  const handleClick = (event, name) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selected, name];
    } else {
      newSelected = selected.filter((selectedName) => selectedName !== name);
    }

    setSelected(newSelected);
    console.log("Row clicked. Name:", name, "New selected:", newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleRenewSelected = () => {
    const selectedClients = clients.filter(client => selected.includes(client.name));
    setSelectedClientsForRenew(selectedClients);
    setMultiRenewModalOpen(true);
  };

  const handleMultiRenewConfirm = async () => {
    try {
      const response = await api.post('/api/multirenew', {
        clients: selectedClientsForRenew.map(client => client.name),
        months: multiRenewCredits
      });

      if (response.data.status === 'OK') {
        setSnackbar({
          open: true,
          message: `Successfully renewed ${multiRenewCredits} month(s) for ${selectedClientsForRenew.length} clients`,
          severity: 'success'
        });
        
        // Refresh clients data
        dispatch(fetchClients());
        
        // Update credits in the sidebar
        dispatch(fetchCredits());

        // Close the modal and reset selection
        setMultiRenewModalOpen(false);
        setSelected([]);
        setSelectedClientsForRenew([]);
        setMultiRenewCredits(1);
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error renewing multiple clients:', error);
      setSnackbar({
        open: true,
        message: 'Error renewing clients. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleFilterChange = (event, newFilter) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  const filteredClients = React.useMemo(() => {
    return clients.filter(client => {
      const searchMatch = Object.values(client).some(value =>
        value != null && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
      
      const expiryStatus = getExpiryStatus(client.expiry);
      
      switch (filter) {
        case 'active':
          // Include both 'active' and 'expiring' clients in the Active tab
          return searchMatch && (expiryStatus === 'active' || expiryStatus === 'expiring');
        case 'expiring':
          return searchMatch && expiryStatus === 'expiring';
        case 'expired':
          return searchMatch && expiryStatus === 'expired';
        default:
          return searchMatch;
      }
    });
  }, [clients, searchTerm, filter]);

  const filterCounts = React.useMemo(() => {
    const counts = {
      all: clients.length,
      active: 0,
      expiring: 0,
      expired: 0
    };

    clients.forEach(client => {
      const expiryStatus = getExpiryStatus(client.expiry);
      if (expiryStatus === 'active' || expiryStatus === 'expiring') {
        counts.active++;
        if (expiryStatus === 'expiring') {
          counts.expiring++;
        }
      } else if (expiryStatus === 'expired') {
        counts.expired++;
      }
    });

    return counts;
  }, [clients]);

  const handleExportClick = (event) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportAnchorEl(null);
  };

  const handleExportCSV = () => {
    console.log('Export to CSV');
    // Implement CSV export logic here
    handleExportClose();
  };

  const handleExportPDF = () => {
    console.log('Export to PDF');
    // Implement PDF export logic here
    handleExportClose();
  };

  const handleClientClick = (clientName) => {
    navigate(`/update-client/${clientName}`);
  };

  const isExpired = (expiryDate) => {
    // Use end of day for comparison to include the full expiry date
    const expiryMoment = moment(expiryDate).endOf('day');
    return moment().isAfter(expiryMoment);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const toggleRowExpansion = (clientName) => {
    setExpandedRows(prev => 
      prev.includes(clientName) 
        ? prev.filter(name => name !== clientName)
        : [...prev, clientName]
    );
  };

  const isRowExpanded = (clientName) => expandedRows.includes(clientName);

  // Add this function to truncate the description
  const truncateDescription = (description, maxLength = 200) => {
    if (description.length <= maxLength) return description;
    return `${description.substring(0, maxLength)}...`;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4">Clients</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ mr: 2 }}
          />
          <Tooltip title="Export">
            <IconButton onClick={handleExportClick} sx={{ mr: 2 }}>
              <ExportIcon />
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={exportAnchorEl}
            open={Boolean(exportAnchorEl)}
            onClose={handleExportClose}
          >
            <MenuItem onClick={handleExportCSV}>Export as CSV</MenuItem>
            <MenuItem onClick={handleExportPDF}>Export as PDF</MenuItem>
          </Menu>
          {isMobile ? (
            <IconButton
              color="primary"
              onClick={handleNewUser}
              aria-label="add new client"
              sx={{
                border: '2px solid',
                borderColor: 'primary.main',
                borderRadius: '50%',
                padding: '8px',
              }}
            >
              <AddIcon />
            </IconButton>
          ) : (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleNewUser}
            >
              New Client
            </Button>
          )}
        </Box>
      </Box>
      <Box sx={{ mb: 2 }}>
        <ToggleButtonGroup
          value={filter}
          exclusive
          onChange={handleFilterChange}
          aria-label="client filter"
        >
          <ToggleButton value="all" aria-label="all clients">
            All ({filterCounts.all})
          </ToggleButton>
          <ToggleButton value="active" aria-label="active clients">
            Active ({filterCounts.active})
          </ToggleButton>
          <ToggleButton value="expiring" aria-label="expiring clients">
            Expiring Soon ({filterCounts.expiring})
          </ToggleButton>
          <ToggleButton value="expired" aria-label="expired clients">
            Expired ({filterCounts.expired})
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <Paper sx={{ width: '100%', mb: 2, overflowX: 'auto' }}>
        <EnhancedTableToolbar 
          numSelected={selected.length} 
          onRenewSelected={handleRenewSelected}
        />
        <TableContainer>
          {status === 'loading' && (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
          <Table sx={{ minWidth: 650 }} size={dense ? "small" : "medium"}>
            <TableHead>
              <TableRow>
                {isMobile && (
                  <TableCell padding="none" sx={{ width: 24 }} />
                )}
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={selected.length > 0 && selected.length < sortedClients.length}
                    checked={sortedClients.length > 0 && selected.length === sortedClients.length}
                    onChange={handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all clients',
                    }}
                  />
                </TableCell>
                {headCells.map((headCell) => (
                  (!isMobile || (headCell.id !== 'description' && headCell.id !== 'ministra')) && (
                    <StyledTableCell
                      key={headCell.id}
                      sortDirection={orderBy === headCell.id ? order : false}
                      style={{ width: headCell.width }}
                    >
                      {headCell.sortable !== false ? (
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : 'asc'}
                          onClick={() => handleRequestSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                    </StyledTableCell>
                  )
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {status === 'loading' ? (
                <TableRow>
                  <StyledTableCell colSpan={8} align="center">
                    <CircularProgress />
                  </StyledTableCell>
                </TableRow>
              ) : status === 'failed' ? (
                <TableRow>
                  <StyledTableCell colSpan={8} align="center">
                    <Typography color="error">Error: {getErrorMessage(error)}</Typography>
                  </StyledTableCell>
                </TableRow>
              ) : filteredClients.length === 0 ? (
                <TableRow>
                  <StyledTableCell colSpan={8} align="center">
                    No clients found
                  </StyledTableCell>
                </TableRow>
              ) : (
                filteredClients
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((client, index) => {
                    const isItemSelected = isSelected(client.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const isExpanded = isRowExpanded(client.name);

                    return (
                      <React.Fragment key={client.name}>
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          selected={isItemSelected}
                          onClick={(event) => handleClick(event, client.name)}
                        >
                          {isMobile && (
                            <TableCell padding="none" sx={{ width: 24 }}>
                              <IconButton size="small" onClick={(e) => {
                                e.stopPropagation();
                                toggleRowExpansion(client.name);
                              }}>
                                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                              </IconButton>
                            </TableCell>
                          )}
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                              onClick={(event) => event.stopPropagation()}
                              onChange={(event) => handleClick(event, client.name)}
                            />
                          </TableCell>
                          <StyledTableCell 
                            component="th" 
                            id={labelId} 
                            scope="row"
                            onClick={() => handleClientClick(client.name)}
                            style={{ cursor: 'pointer', fontSize: '0.875rem' }}
                            className="name-cell"
                            isMobile={isMobile}
                          >
                            {client.name}
                          </StyledTableCell>
                          <StyledTableCell
                            onClick={() => handleClientClick(client.name)}
                            style={{ cursor: 'pointer', fontSize: '0.875rem' }}
                            isMobile={isMobile}
                          >
                            {client.mac}
                          </StyledTableCell>
                          {!isMobile && (
                            <StyledTableCell align="center">
                              {client.ministra ? <CheckIcon color="primary" /> : null}
                            </StyledTableCell>
                          )}
                          <StyledTableCell>
                            <ExpirySpan expiryStatus={getExpiryStatus(client.expiry)}>
                              {moment(client.expiry).format('YYYY-MM-DD')}
                            </ExpirySpan>
                          </StyledTableCell>
                          {!isMobile && (
                            <StyledTableCell 
                              sx={{ 
                                maxWidth: 200, 
                                overflow: 'hidden', 
                                textOverflow: 'ellipsis', 
                                whiteSpace: 'nowrap' 
                              }}
                            >
                              {truncateDescription(client.description)}
                            </StyledTableCell>
                          )}
                          <StyledTableCell>
                            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                              <StatusIcon status={client.status} />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            <IconButton onClick={(e) => { e.stopPropagation(); handleRenew(client); }} size="small">
                              <AutorenewIcon />
                            </IconButton>
                            <IconButton 
                              onClick={(e) => { e.stopPropagation(); handleDelete(client); }} 
                              size="small"
                              disabled={!isExpired(client.expiry)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </StyledTableCell>
                        </TableRow>
                        {isMobile && isExpanded && (
                          <TableRow>
                            <TableCell colSpan={7}>
                              <Box sx={{ pl: 2 }}>
                                <Typography><strong>Ministra:</strong> {client.ministra ? 'Yes' : 'No'}</Typography>
                                <Typography><strong>Description:</strong> {client.description}</Typography>
                              </Box>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    );
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={filteredClients.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense"
      />
      <Dialog open={renewModalOpen} onClose={() => setRenewModalOpen(false)}>
        <DialogTitle>
          Renew Credits for {selectedClient ? selectedClient.name : ''}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Credits to Renew</InputLabel>
            <Select
              value={renewCredits}
              onChange={(e) => setRenewCredits(e.target.value)}
            >
              <MenuItem value={0}>Select credits</MenuItem>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => (
                <MenuItem key={month} value={month}>{month} Month{month > 1 ? 's' : ''}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRenewModalOpen(false)}>Cancel</Button>
          <Button onClick={handleRenewConfirm} disabled={renewCredits === 0}>Renew</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the client "{clientToDelete?.name}"?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* Add new Modal for Multiple Client Renewal */}
      <Dialog
        open={multiRenewModalOpen}
        onClose={() => setMultiRenewModalOpen(false)}
        aria-labelledby="multi-renew-dialog-title"
        aria-describedby="multi-renew-dialog-description"
      >
        <DialogTitle id="multi-renew-dialog-title">Renew Multiple Clients</DialogTitle>
        <DialogContent>
          <DialogContentText id="multi-renew-dialog-description">
            You are about to renew the following clients:
          </DialogContentText>
          <List>
            {selectedClientsForRenew.map((client) => (
              <ListItem key={client.name}>
                <ListItemText primary={client.name} />
              </ListItem>
            ))}
          </List>
          <FormControl fullWidth margin="normal">
            <InputLabel>Credits to Renew</InputLabel>
            <Select
              value={multiRenewCredits}
              onChange={(e) => setMultiRenewCredits(e.target.value)}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(month => (
                <MenuItem key={month} value={month}>{month} Month{month > 1 ? 's' : ''}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMultiRenewModalOpen(false)}>Cancel</Button>
          <Button onClick={handleMultiRenewConfirm} color="primary">Renew</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Clients;
