import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';
import moment from 'moment';
import { 
  Box, 
  Typography, 
  Grid, 
  Card, 
  CardContent, 
  CardActionArea,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TextField,
  TableSortLabel,
  Paper,
  styled,
} from '@mui/material';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

const DashboardCard = ({ title, count, onClick, backgroundColor, icon: Icon }) => (
  <Grid item xs={12}>
    <Card 
      sx={{ 
        backgroundColor, 
        boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
        '&:hover': {
          boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
        },
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <CardActionArea onClick={onClick}>
        <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative', zIndex: 1 }}>
          <Box>
            <Typography variant="h6" component="div" sx={{ color: 'rgba(255, 255, 255, 0.85)', fontWeight: 'medium' }}>
              {title}
            </Typography>
            <Typography variant="h3" component="div" sx={{ color: 'white', mt: 1 }}>
              {count}
            </Typography>
          </Box>
        </CardContent>
        <Box
          sx={{
            position: 'absolute',
            top: '-20%',
            left: '-10%',
            opacity: 0.2,
            transform: 'rotate(-10deg)',
            zIndex: 0,
          }}
        >
          <Icon sx={{ fontSize: 180, color: 'white' }} />
        </Box>
      </CardActionArea>
    </Card>
  </Grid>
);

const TypeSpan = styled('span')(({ theme, type }) => ({
  padding: '4px 8px',
  borderRadius: '4px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '0.75rem',
  backgroundColor: 
    type === 'USED' || type === 'DELETE' ? theme.palette.error.light :
    type === 'RETRIEVED' ? theme.palette.success.light :
    'transparent',
  color: 
    type === 'USED' || type === 'DELETE' || type === 'RETRIEVED' ? theme.palette.common.white :
    theme.palette.text.primary,
}));

const ResellerDashboard = () => {
  const navigate = useNavigate();
  const [dealersCount, setDealersCount] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState('created');
  const [order, setOrder] = useState('desc');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchDealersCount();
    fetchTransactions();
  }, []);

  const fetchDealersCount = async () => {
    try {
      const response = await api.get('/reseller/list');
      setDealersCount(response.data.count);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching dealers count:', error);
      setLoading(false);
    }
  };

  const fetchTransactions = async () => {
    try {
      const response = await api.get('/reseller/transactions');
      setTransactions(response.data);
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filteredTransactions = transactions.filter(transaction =>
    Object.values(transaction).some(value =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const sortedTransactions = React.useMemo(() => {
    const comparator = (a, b) => {
      if (b[orderBy] < a[orderBy]) {
        return order === 'asc' ? 1 : -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return order === 'asc' ? -1 : 1;
      }
      return 0;
    };

    return [...filteredTransactions].sort(comparator);
  }, [filteredTransactions, order, orderBy]);

  const headCells = [
    { id: 'created', label: 'Date' },
    { id: 'type', label: 'Type' },
    { id: 'credits', label: 'Credits', numeric: true },
    { id: 'login', label: 'Login' },
    { id: 'mac', label: 'MAC' },
    { id: 'description', label: 'Description' },
    { id: 'expiry', label: 'Expiry' },
  ];

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Reseller Dashboard
      </Typography>
      <Grid container spacing={3} sx={{ mb: 4 }}>
        <DashboardCard 
          title="Dealers" 
          count={dealersCount} 
          onClick={() => navigate('/dealers')} 
          backgroundColor="rgb(38,194,129)"
          icon={SupervisorAccountIcon}
        />
      </Grid>
      
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
          <Typography variant="h5">
            Latest Transactions
          </Typography>
          <TextField
            label="Search Transactions"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ width: 300 }}
          />
        </Box>
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="transactions table">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? 'right' : 'left'}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : 'asc'}
                      onClick={() => handleRequestSort(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedTransactions.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No transactions found
                  </TableCell>
                </TableRow>
              ) : (
                sortedTransactions
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((transaction, index) => (
                    <TableRow key={index}>
                      <TableCell>{moment(transaction.created).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                      <TableCell>
                        <TypeSpan type={transaction.type}>
                          {transaction.type}
                        </TypeSpan>
                      </TableCell>
                      <TableCell align="right">{transaction.credits}</TableCell>
                      <TableCell>{transaction.login}</TableCell>
                      <TableCell>{transaction.mac}</TableCell>
                      <TableCell>{transaction.description}</TableCell>
                      <TableCell>{moment(transaction.expiry).format('YYYY-MM-DD')}</TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredTransactions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default ResellerDashboard;