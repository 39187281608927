import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Typography, 
  Box, 
  Card, 
  CardContent,
  CardHeader,
  TextField, 
  Checkbox, 
  FormControlLabel, 
  Button, 
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
  Divider,
  useTheme,
  alpha
} from '@mui/material';
import MaskedInput from 'react-text-mask';
import api from '../services/api';

const TextMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={['0', '0', ':' , '1', 'A', ':', '7', '9', ':', /[A-Fa-f0-9]/, /[A-Fa-f0-9]/, ':', /[A-Fa-f0-9]/, /[A-Fa-f0-9]/, ':', /[A-Fa-f0-9]/, /[A-Fa-f0-9]/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};

const PhoneMaskCustom = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
};

const NewClient = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const availablePackages = [
    { value: 1, label: 'All Packages' },
    { value: 7, label: 'All Packages/No Adults' },
    { value: 2, label: 'English/Sports/Adults' },
    { value: 3, label: 'South Asian/English/Sports/Adults' },
    { value: 4, label: 'South Asian/English/Sports/No Adults' },
    { value: 5, label: 'English/Portuguese/Brazil/Sports/Adults' },
    { value: 13, label: 'English/Sports/No Adults' },
    { value: 14, label: 'English/Sports/Portuguese/Brasil/Adults' },
    { value: 15, label: 'English/Sports/Portuguese/Brasil/No Adults' },
    { value: 16, label: 'English/Sports/Spanish/Adults' },
    { value: 17, label: 'English/Sports/Spanish/No Adults' },
    { value: 18, label: 'English/Sports/Portuguese/Brazilian/Italian/Spanish/South America/Latino/Adults' },
    { value: 19, label: 'English/Sports/Portuguese/Brazilian/Italian/Spanish/South America/Latino/No Adults' },
    { value: 20, label: 'English/Sports/Spanish/Portuguese/Italian/Adults' },
    { value: 21, label: 'English/Sports/Spanish/Portuguese/Italian/No Adults' },
    { value: 22, label: 'English/Sports/Caribbean/Adults' },
    { value: 23, label: 'English/Sports/Caribbean/No Adults' },
    { value: 24, label: 'English/Sports/Caribbean/South Asian/No Adults' },
    { value: 25, label: 'English/Sports/Caribbean/South Asian/Adults' },
    { value: 26, label: 'English/Sports/Ex Yugoslavia/Adults' },
    { value: 27, label: 'English/Sports/Ex Yugoslavia/No Adults' },
    { value: 28, label: 'English/Sports/Portuguese/Brasil/Italian/Spanish/Adults' },
    { value: 32, label: 'English/Sports/Portuguese/Brasil/Italian/Spanish/No Adults' },
    { value: 33, label: 'English/Sports/Italian/Adults' },
    { value: 34, label: 'English/Sports/Italian/No Adults' },
    { value: 35, label: 'English/Sports/Latino/Mexico/Spain/South America/Adults' },
    { value: 36, label: 'English/Sports/Latino/Mexico/Spain/South America/No Adults' },
    { value: 37, label: 'English/Sports/Arabic/No Adults' },
    { value: 38, label: 'English/Sports/Arabic/Adults' },
    { value: 39, label: 'English/Sports/Greek/Adults' },
    { value: 40, label: 'English/Sports/Greek/No Adults' },
    { value: 41, label: 'English/Sports/Polish/Romania/Ex Yugoslavia/Adults' },
    { value: 42, label: 'English/Sports/Polish/Romania/Ex Yugoslavia/No Adults' },
    { value: 43, label: 'English/Sports/French CA/African/French/No Adults' },
    { value: 44, label: 'English/Sports/French CA/African/French/Adults' },
    { value: 45, label: 'English/Sports/German/No Adults' },
    { value: 46, label: 'English/Sports/German/Adults' },
    { value: 47, label: 'English/Sports/Vietnamese/Korean/Chinese/Filipino/No Adults' },
    { value: 48, label: 'English/Sports/Vietnamese/Korean/Chinese/Filipino/Adults' },
    { value: 49, label: 'English/Sports/Hindi/Urdu/Punjabi/Adults' },
    { value: 50, label: 'English/Sports/Hindi/Urdu/Punjabi/No Adults' },
  ];

  const creditOptions = [
    { value: 0, label: 'Trial - Expires at midnight' },
    { value: '2d', label: 'Trial - Expires tomorrow midnight' },
    { value: 1, label: '1 Month' },
    { value: 2, label: '2 Months' },
    { value: 3, label: '3 Months' },
    { value: 4, label: '4 Months' },
    { value: 5, label: '5 Months' },
    { value: 6, label: '6 Months' },
    { value: 7, label: '7 Months' },
    { value: 8, label: '8 Months' },
    { value: 9, label: '9 Months' },
    { value: 10, label: '10 Months' },
    { value: 11, label: '11 Months' },
    { value: 12, label: '12 Months' },
  ];

  const [formData, setFormData] = useState({
    ministra: false,
    loginName: '',
    password: '',
    description: '',
    phoneNumber: '',
    macAddress: '',
    credits: creditOptions[0].value,
    package: availablePackages[0].value
  });

  const [validity, setValidity] = useState({
    loginName: false,
    password: false,
    description: true,
    phoneNumber: true,
    macAddress: true
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    validateForm();
  }, [formData]);

  const validateForm = () => {
    setValidity({
      loginName: formData.loginName.length >= 5 && formData.loginName.length <= 15,
      password: formData.password.length >= 5 && formData.password.length <= 15,
      description: formData.description.length <= 100,
      phoneNumber: formData.phoneNumber.replace(/\D/g, '').length <= 15,
      macAddress: formData.ministra || formData.macAddress.replace(/[^A-Fa-f0-9]/g, '').length === 12
    });
  };

  const isFormValid = () => {
    return Object.values(validity).every(Boolean);
  };

  const handleChange = (event) => {
    const { name, value, checked } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: name === 'ministra' ? checked : value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!isFormValid()) return;

    try {
      const response = await api.post('/api/client', {
        login: formData.loginName,
        password: formData.password,
        stb_mac: formData.macAddress,
        ministra: formData.ministra,
        months: formData.credits,
        desc: formData.description,
        phone: formData.phoneNumber,
        package_selection: formData.package
      });

      if (response.data.status === 'OK') {
        console.log('Client created successfully:', response.data);
        setSnackbar({
          open: true,
          message: `Client ${response.data.name} created successfully!`,
          severity: 'success'
        });
        
        // Delay the navigation to allow the snackbar to be visible
        setTimeout(() => {
          navigate('/clients');
        }, 3000); // 3 seconds delay
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      let errorMessage = 'Error creating client. Please try again.';
      
      if (error.response && error.response.status === 400 && error.response.data.message) {
        errorMessage = error.response.data.message;
      }
      
      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error'
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const cardStyle = {
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    '&:hover': {
      boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
    },
  };

  const cardHeaderStyle = {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    color: theme.palette.common.black,
    padding: '16px',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  };

  const cardTitleStyle = {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: theme.palette.common.black,
  };

  return (
    <Box sx={{ width: '100%', padding: 1 }}>
      <Card sx={cardStyle}>
        <CardHeader 
          title="New Client"
          titleTypographyProps={{ variant: 'h6', ...cardTitleStyle }}
          sx={cardHeaderStyle}
        />
        <Divider />
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formData.ministra}
                      onChange={handleChange}
                      name="ministra"
                    />
                  }
                  label="Ministra"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Login Name"
                  name="loginName"
                  value={formData.loginName}
                  onChange={handleChange}
                  error={!validity.loginName}
                  helperText={!validity.loginName ? "Login should be between 5 and 15 characters" : ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  error={!validity.password}
                  helperText={!validity.password ? "Password should be between 5 and 15 characters" : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  multiline
                  rows={4}
                  value={formData.description}
                  onChange={handleChange}
                  error={!validity.description}
                  helperText={!validity.description ? "Description cannot exceed 100 characters" : ""}
                />
              </Grid>
              {!formData.ministra && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="MAC Address"
                    name="macAddress"
                    value={formData.macAddress}
                    onChange={handleChange}
                    InputProps={{
                      inputComponent: TextMaskCustom,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    error={!validity.macAddress}
                    helperText={!validity.macAddress ? "MAC address should be 17 characters long" : ""}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  InputProps={{
                    inputComponent: PhoneMaskCustom,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  error={!validity.phoneNumber}
                  helperText={!validity.phoneNumber ? "Phone number should be less than 15 characters" : ""}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="credits-label">Credits</InputLabel>
                  <Select
                    labelId="credits-label"
                    name="credits"
                    value={formData.credits}
                    onChange={handleChange}
                  >
                    {creditOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="package-label">Package</InputLabel>
                  <Select
                    labelId="package-label"
                    name="package"
                    value={formData.package}
                    onChange={handleChange}
                  >
                    {availablePackages.map((pkg) => (
                      <MenuItem key={pkg.value} value={pkg.value}>
                        {pkg.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary" disabled={!isFormValid()}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default NewClient;