import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchClients as fetchClientsApi } from '../../services/api';

export const fetchClients = createAsyncThunk(
  'clients/fetchClients',
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchClientsApi();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  clients: [],
  count: 0,
  currentPage: 1,
  perPage: 10,
  totalPages: 1,
  status: 'idle',
  error: null,
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClients.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clients = action.payload.data;
        state.count = action.payload.count;
        state.currentPage = action.payload.current_page;
        state.perPage = action.payload.per_page;
        state.totalPages = action.payload.total_pages;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default clientsSlice.reducer;