import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Typography, 
  Box, 
  Card, 
  CardContent,
  CardHeader,
  TextField, 
  Button, 
  Grid,
  Snackbar,
  Alert,
  Divider,
  useTheme,
  alpha
} from '@mui/material';
import api from '../services/api';

const AddSuperReseller = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [formData, setFormData] = useState({
    login: '',
    password: ''
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await api.post('/admin/superreseller', formData);
      if (response.data.status === 'OK') {
        setSnackbar({
          open: true,
          message: 'Super Reseller added successfully!',
          severity: 'success'
        });
        setTimeout(() => {
          navigate('/super-resellers');
        }, 2000);
      } else {
        throw new Error('Failed to add Super Reseller');
      }
    } catch (error) {
      console.error('Error adding Super Reseller:', error);
      setSnackbar({
        open: true,
        message: 'Error adding Super Reseller. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const cardStyle = {
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    '&:hover': {
      boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
    },
  };

  const cardHeaderStyle = {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    color: theme.palette.common.black,
    padding: '16px',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  };

  const cardTitleStyle = {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: theme.palette.common.black,
  };

  return (
    <Box sx={{ width: '100%', padding: 3 }}>
      <Card sx={cardStyle}>
        <CardHeader 
          title="Add Super Reseller"
          titleTypographyProps={{ variant: 'h6', ...cardTitleStyle }}
          sx={cardHeaderStyle}
        />
        <Divider />
        <CardContent>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Login"
                  name="login"
                  value={formData.login}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary">
                  Add Super Reseller
                </Button>
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AddSuperReseller;