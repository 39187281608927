import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  CardHeader,
  Snackbar,
  Alert,
  CircularProgress,
  styled,
  useTheme,
  alpha,
  Divider
} from '@mui/material';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import api from '../services/api';

const MessageBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: alpha(theme.palette.background.default, 0.6),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'radial-gradient(circle at 100% 100%, rgba(204,204,204,0.2) 0, rgba(204,204,204,0.2) 2px, transparent 2px), radial-gradient(circle at 0 0, rgba(204,204,204,0.2) 0, rgba(204,204,204,0.2) 2px, transparent 2px)',
    backgroundSize: '20px 20px',
    opacity: 0.3,
    pointerEvents: 'none',
  }
}));

const Message = () => {
  const [currentMessage, setCurrentMessage] = useState('');
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    fetchCurrentMessage();
  }, []);

  const fetchCurrentMessage = async () => {
    try {
      const response = await api.get('/admin/message');
      setCurrentMessage(response.data.message);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching current message:', error);
      setSnackbar({ open: true, message: 'Error fetching current message', severity: 'error' });
      setLoading(false);
    }
  };

  const handleUpdateMessage = async () => {
    try {
      const response = await api.post('/admin/message', { message: newMessage });
      if (response.data.status === 'OK') {
        setSnackbar({ open: true, message: 'Message updated successfully', severity: 'success' });
        setTimeout(() => navigate('/admin'), 2000);
      } else {
        throw new Error('Failed to update message');
      }
    } catch (error) {
      console.error('Error updating message:', error);
      setSnackbar({ open: true, message: 'Error updating message', severity: 'error' });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const cardStyle = {
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
    transition: '0.3s',
    '&:hover': {
      boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
    },
  };

  const cardHeaderStyle = {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
    color: theme.palette.common.black,
    padding: '16px',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  };

  const cardTitleStyle = {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: theme.palette.common.black,
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', padding: 3 }}>
      <Card sx={cardStyle}>
        <CardHeader 
          title="Update Message"
          titleTypographyProps={{ variant: 'h6', ...cardTitleStyle }}
          sx={cardHeaderStyle}
        />
        <Divider />
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Current Message:
          </Typography>
          <MessageBox>
            <Box sx={{ position: 'relative', p: 2 }}>
              <FormatQuoteIcon sx={{ position: 'absolute', top: -10, left: -10, fontSize: 40, opacity: 0.2 }} />
              <Typography variant="body1" paragraph sx={{ fontStyle: 'italic', pl: 4, pr: 4 }}>
                {currentMessage}
              </Typography>
              <FormatQuoteIcon sx={{ position: 'absolute', bottom: -10, right: -10, fontSize: 40, opacity: 0.2, transform: 'rotate(180deg)' }} />
            </Box>
          </MessageBox>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            label="New Message"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            sx={{ mt: 3, mb: 2 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateMessage}
            disabled={!newMessage.trim()}
          >
            Update Message
          </Button>
        </CardContent>
      </Card>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Message;