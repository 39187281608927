import React, { useState, useEffect, useCallback } from 'react';
import { Drawer, Box, Typography, IconButton, Grid, Divider, Button, Snackbar, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import CircleIcon from '@mui/icons-material/Circle';
import api from '../services/api';
import { useSelector } from 'react-redux';

const SettingsDrawer = ({ open, onClose, settings, onUpdateSettings }) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const role = useSelector((state) => state.auth.role);

  //useEffect(() => {
  //  setHasUnsavedChanges(false);
  //  console.log('useEffect:: unsaved changes', false);
  //}, [settings]);

  const handleChange = useCallback((key, value) => {
    onUpdateSettings({ ...settings, [key]: value });
    setHasUnsavedChanges(true);
    console.log('handleChange:: unsaved changes', true);
  }, [settings, onUpdateSettings]);

  const handleSave = async () => {
    try {
      let endpoint;
      switch (role) {
        case 'admin':
          endpoint = '/admin/savesettings';
          break;
        case 'superreseller':
          endpoint = '/superreseller/savesettings';
          break;
        case 'reseller':
          endpoint = '/reseller/savesettings';
          break;
        case 'dealer':
        default:
          endpoint = '/api/savesettings';
          break;
      }

      const response = await api.post(endpoint, settings);
      if (response.status === 200) {
        setSnackbarMessage('Settings saved successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setHasUnsavedChanges(false);
        console.log('handleSave:: unsaved changes', false);
      }
    } catch (error) {
      console.error('Error saving settings:', error);
      setSnackbarMessage('Failed to save settings');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const presets = [
    { color: '#00ab55', name: 'green' },
    { color: '#7635dc', name: 'purple' },
    { color: '#1ccaff', name: 'aqua' },
    { color: '#2065d1', name: 'blue' },
    { color: '#fda92d', name: 'orange' },
    { color: '#ff3030', name: 'red' },
  ];

  return (
    <>
      <Drawer
        anchor={settings.layout === 'left' ? 'right' : 'left'}
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: 300,
            height: '95%',
            top: '2.5%',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(10px)',
            borderTopLeftRadius: 16,
            borderBottomLeftRadius: 16,
            ...(settings.mode === 'dark' && {
              backgroundColor: 'rgba(32, 42, 53, 0.8)',
            }),
          },
        }}
        BackdropProps={{
          sx: {
            backgroundColor: 'transparent',
          },
        }}
        ModalProps={{
          BackdropProps: {
            invisible: true,
          },
        }}
      >
        <Box sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" fontWeight="bold">Settings</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider sx={{ mb: 2 }} />
          <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
            <Typography variant="subtitle1" gutterBottom fontWeight="bold">Mode</Typography>
            <Grid container spacing={2} justifyContent="center" sx={{ mb: 4 }}>
              <Grid item>
                <IconButton 
                  onClick={() => handleChange('mode', 'light')}
                  color={settings.mode === 'light' ? 'primary' : 'default'}
                  sx={{
                    padding: 2,
                    border: 2,
                    borderColor: settings.mode === 'light' ? 'primary.main' : 'grey.300',
                    borderRadius: '16px',
                  }}
                >
                  <LightModeIcon sx={{ fontSize: 40 }} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton 
                  onClick={() => handleChange('mode', 'dark')}
                  color={settings.mode === 'dark' ? 'primary' : 'default'}
                  sx={{
                    padding: 2,
                    border: 2,
                    borderColor: settings.mode === 'dark' ? 'primary.main' : 'grey.300',
                    borderRadius: '16px',
                  }}
                >
                  <DarkModeIcon sx={{ fontSize: 40 }} />
                </IconButton>
              </Grid>
            </Grid>
            <Typography variant="subtitle1" gutterBottom fontWeight="bold">Layout</Typography>
            <Grid container spacing={2} justifyContent="center" sx={{ mb: 4 }}>
              <Grid item>
                <IconButton 
                  onClick={() => handleChange('layout', 'left')}
                  color={settings.layout === 'left' ? 'primary' : 'default'}
                  sx={{
                    padding: 2,
                    border: 2,
                    borderColor: settings.layout === 'left' ? 'primary.main' : 'grey.300',
                    borderRadius: '16px',
                  }}
                >
                  <MenuOpenIcon sx={{ fontSize: 40 }} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton 
                  onClick={() => handleChange('layout', 'right')}
                  color={settings.layout === 'right' ? 'primary' : 'default'}
                  sx={{
                    padding: 2,
                    border: 2,
                    borderColor: settings.layout === 'right' ? 'primary.main' : 'grey.300',
                    borderRadius: '16px',
                  }}
                >
                  <MenuOpenRoundedIcon sx={{ fontSize: 40, transform: 'scaleX(-1)' }} />
                </IconButton>
              </Grid>
            </Grid>
            <Typography variant="subtitle1" gutterBottom fontWeight="bold">Preset</Typography>
            <Grid container spacing={2} justifyContent="center">
              {presets.map((preset) => (
                <Grid item key={preset.name}>
                  <IconButton 
                    onClick={() => handleChange('preset', preset.name)}
                    sx={{
                      padding: 1.5,
                      border: 2,
                      borderColor: settings.preset === preset.name ? preset.color : 'grey.300',
                      borderRadius: '50%',
                    }}
                  >
                    <CircleIcon sx={{ fontSize: 30, color: preset.color }} />
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          </Box>
          {hasUnsavedChanges && (
            <Box sx={{ mt: 2 }}>
              <Button 
                variant="contained" 
                color="primary" 
                fullWidth 
                onClick={handleSave}
              >
                Save Changes
              </Button>
            </Box>
          )}
        </Box>
      </Drawer>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SettingsDrawer;
