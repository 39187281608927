import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box, Typography, Divider, CircularProgress } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import AddIcon from '@mui/icons-material/Add';
import LogoutIcon from '@mui/icons-material/Logout';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MessageIcon from '@mui/icons-material/Message';
import DeleteIcon from '@mui/icons-material/Delete';
import { logout, fetchCredits } from '../features/auth/authSlice';

const drawerWidth = 240;
const collapsedDrawerWidth = 60;

const Sidebar = ({ position, isCollapsed, appBarHeight, credits, onMobileNavClick }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const role = useSelector((state) => state.auth.role);
  const [creditsLoading, setCreditsLoading] = useState(true);

  useEffect(() => {
    if (role === 'dealer' || role === 'superreseller' || role === 'reseller') {
      dispatch(fetchCredits())
        .then(() => setCreditsLoading(false))
        .catch(() => setCreditsLoading(false));
    }
  }, [dispatch, role]);

  const getMenuItems = () => {
    switch (role) {
      case 'admin':
        return [
          { text: 'Dashboard', icon: <DashboardIcon />, path: '/admin' },
          { text: 'Add Super Reseller', icon: <AddCircleOutlineIcon />, path: '/add-super-reseller' },
          { text: 'Super Resellers', icon: <SupervisorAccountIcon />, path: '/super-resellers' },
          { text: 'Message', icon: <MessageIcon />, path: '/admin/message' },
          { text: 'Delete Mac', icon: <DeleteIcon />, path: '/admin/delete-mac' },
        ];
      case 'superreseller':
        return [
          { text: 'Dashboard', icon: <DashboardIcon />, path: '/superreseller' },
          { text: 'Add Reseller', icon: <AddCircleOutlineIcon />, path: '/add-reseller' },
          { text: 'Resellers', icon: <SupervisorAccountIcon />, path: '/resellers' },
        ];
      case 'reseller':
        return [
          { text: 'Dashboard', icon: <DashboardIcon />, path: '/reseller' },
          { text: 'Add Dealer', icon: <AddCircleOutlineIcon />, path: '/add-dealer' },
          { text: 'Dealers', icon: <SupervisorAccountIcon />, path: '/dealers' },
        ];
      case 'dealer':
      default:
        return [
          { text: 'Dashboard', icon: <DashboardIcon />, path: '/' },
          { text: 'New Client', icon: <AddIcon />, path: '/new-client' },
          { text: 'Clients', icon: <PeopleIcon />, path: '/clients' },
        ];
    }
  };

  const menuItems = getMenuItems();

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  const getCreditsColor = (credits) => {
    if (credits > 30) return 'success.main';
    if (credits > 0) return 'warning.main';
    return 'error.main';
  };

  const drawer = (
    <>
      {!isCollapsed && (role === 'dealer' || role === 'superreseller' || role === 'reseller') && (
        <Box sx={{ bgcolor: 'background.default', p: 2, mb: 2 }}>
          <ListItem>
            <ListItemIcon>
              <AttachMoneyIcon sx={{ color: getCreditsColor(credits) }} />
            </ListItemIcon>
            <ListItemText 
              primary="Credits"
              secondary={
                creditsLoading 
                  ? <CircularProgress size={20} /> 
                  : <Typography variant="h6" sx={{ color: getCreditsColor(credits) }}>{credits}</Typography>
              } 
            />
          </ListItem>
        </Box>
      )}
      {(role === 'admin') && (
        <Box sx={{ bgcolor: 'background.default', p: 2, mb: 2 }}>
          <ListItem>
            <ListItemIcon>
              <AllInclusiveIcon color="primary" />
            </ListItemIcon>
            {!isCollapsed && (
              <ListItemText 
                primary="Credits"
                secondary={
                  <Typography variant="h6" color="primary">Unlimited</Typography>
                } 
              />
            )}
          </ListItem>
        </Box>
      )}
      <Divider />
      <List>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            component={Link}
            to={item.path}
            onClick={onMobileNavClick}
            selected={location.pathname === item.path}
            sx={{ 
              justifyContent: isCollapsed ? 'center' : 'flex-start',
              px: isCollapsed ? 0 : 2
            }}
          >
            <ListItemIcon sx={{ minWidth: isCollapsed ? 0 : 48 }}>{item.icon}</ListItemIcon>
            {!isCollapsed && <ListItemText primary={item.text} />}
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        <ListItem
          button
          onClick={() => {
            handleLogout();
            if (onMobileNavClick) onMobileNavClick();
          }}
          sx={{ 
            justifyContent: isCollapsed ? 'center' : 'flex-start',
            px: isCollapsed ? 0 : 2
          }}
        >
          <ListItemIcon sx={{ minWidth: isCollapsed ? 0 : 48 }}><LogoutIcon /></ListItemIcon>
          {!isCollapsed && <ListItemText primary="Logout" />}
        </ListItem>
      </List>
    </>
  );

  return (
    <Drawer
      variant="permanent"
      anchor={position}
      sx={{
        width: isCollapsed ? collapsedDrawerWidth : drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { 
          width: isCollapsed ? collapsedDrawerWidth : drawerWidth, 
          boxSizing: 'border-box',
          transition: 'width 0.3s ease-in-out',
          overflowX: 'hidden',
          top: `${appBarHeight}px`,
          height: `calc(100% - ${appBarHeight}px)`,
        },
      }}
      open
    >
      {drawer}
    </Drawer>
  );
};

export default Sidebar;
